import React from 'react';


import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/HeaderPosts';

import avatar from '../assets/images/avatar-w.png';

import dev from '../assets/images/devlogo.png';
import design from '../assets/images/design-logo-dark.png';
import music from '../assets/images/music-logo.png';

import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
// import '../css/index.css'; // add some style if you want!
export default function Index({ data }) {

const { edges: posts } = data.allMarkdownRemark




return (
<Layout>

  <Header />

  <section id="head-post" className="about-section about-section2 text-left">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 mx-auto pad-me">
          <h2 className="text-white mb-0">Projects</h2>
        </div>
      </div>
    </div>
  </section>

  <div className="blog-posts pad-o-matic">
    {posts
    .filter(post => post.node.frontmatter.title.length > 0)
    .map(({ node: post }) => {
    return (
    <div className="container pad-o-wan">

      <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
        <div className="col-lg-8 center pcard">
          <a href={`${post.frontmatter.path}`}>
            <div className="blog-post-preview" key={post.id}>
              <h1>
                {post.frontmatter.title}
              </h1>
              <h2>{post.frontmatter.date}</h2>
              <p>{post.excerpt}</p>
              <ul className="list-group list-group-horizontal">
                {post.frontmatter.tags.map(tag => {
                  return (
                    <li className="list-group-item">
                      <Link to={`/tags/${tag}`}>
                        {tag}
                      </Link>
                    </li>
                  )
                })}

                <li className="list-group-item list-all">
                  <Link to="/tags">
                    All tags
                  </Link>
                </li>

              </ul>

            </div>
          </a>
        </div>

      </div>
    </div>
    )
    })}
  </div>



  {/*TODO make contact form*/}

  <SocialLinks />

  <Footer />
  {/*TODO finish footer*/}

</Layout>
)
}
export const pageQuery = graphql`
query IndexQuery {
allMarkdownRemark(filter: {
  frontmatter: { cat: { eq: "projects" }}}
  sort: { order: DESC, fields: [frontmatter___date] }) {
edges {
node {
excerpt(pruneLength: 250)
id
frontmatter {
title
date(formatString: "MMMM DD, YYYY")
path
tags
}
}
}
}
}
`
